<template>
  <div class="block w-100 mb-3" id="carousel">
    <el-carousel height="50vh">
      <el-carousel-item v-for="item in carouselImage" :key="item" arrow="always">
        <img :src="item" alt="img" />
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="container">
    <div class="row mb-3">
      <div class="col-12 mb-2">
        <div class="baseline text-start my-2 pb-2 border-bottom border-2">
          <h5 class="fw-bold fs-3 d-inline-block me-2"><i class="fas fa-info-circle me-2"></i>課程諮詢</h5>
          <span class="">對於自身學習較模糊者</span>
        </div>
        <div class="consult">
          <img src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
        </div>
      </div>
    </div>
    <div class="row mb-3 class">
      <div class="col-12 mb-2">
        <div class="baseline text-start my-2 pb-2 border-bottom border-2">
          <h5 class="fw-bold fs-3 d-inline-block me-2"><i class="fas fa-music me-2"></i>音樂課程</h5>
          <span class="">老師開課資訊</span>
        </div>
      </div>
      <div class="col-6 col-md-3 mb-3" v-for="item in musicClassFilter" :key="item">
        <el-card class="card w-100" @click="redirectToClass(item.id)">
          <div class="position-relative">
            <img :src="item.imgUrl" class="card-img-top" alt="..." />
            <span class="badge bg-warning text-dark w-50 position-absolute text-white" style="top: 75%; left: 0">$ {{ currencyFormat(item.price) }} / 堂</span>
          </div>
          <div class="card-body">
            <div class="border-2 border-bottom pb-3 mb-2 w-100 content">
              <h5 class="card-title text-start fw-bolder fs-5">
                {{ item.title }}
              </h5>
              <div class="card-text text-start fs-6 info">
                {{ item.info }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <a href="#" class="text-secondary text-decoration-none fw-bolder"> {{ item.teacher }}</a>
              <a href="#" class="text-secondary text-decoration-none fw-bolder"> {{ item.degree }}</a>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row mb-3 teacher">
      <div class="col-12 mb-2">
        <div class="baseline text-start my-2 pb-2 border-bottom border-2">
          <h5 class="fw-bold fs-3 d-inline-block me-2"><i class="fas fa-music me-2"></i>老師資訊</h5>
          <span class="">近期加入的老師</span>
        </div>
      </div>
      <div class="col-6 col-md-3 mb-3" v-for="item in teacherFilter" :key="item">
        <el-card class="card w-100" @click="redirectToTeacher(item.id)">
          <div class="position-relative">
            <img :src="item.imgUrl" class="card-img-top" alt="..." />
          </div>
          <div class="card-body">
            <div class="pb-3 mb-2 w-100">
              <h5 class="card-title text-start border-2 border-bottom fw-bolder fs-5">
                {{ item.name }}
              </h5>
              <div class="card-text text-start fs-6 info">
                {{ item.info }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      classData: [],
      teacherData: [],
      carouselImage: [
        "https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "https://images.unsplash.com/photo-1514119412350-e174d90d280e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "https://images.unsplash.com/photo-1496293455970-f8581aae0e3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1113&q=80",
      ],
    };
  },
  computed: {
    musicClassFilter() {
      if (this.$store.getters.mobileSize) return this.classData.slice(0, 4);
      else return this.classData.slice(0, 8);
    },
    teacherFilter() {
      if (this.$store.getters.mobileSize) return this.teacherData.slice(0, 4);
      else return this.teacherData.slice(0, 8);
    },
  },
  async created() {
    await this.initData();
  },
  methods: {
    async initData() {
      const vm = this;
      await vm
        .fetchAPI("get", `${process.env.VUE_APP_URL_API}/Courses/New`)
        .then((res) => {
          res.data.courses.forEach((item) => {
            vm.classData.push({
              imgUrl: vm.imageProcess(item.cover, "cover"),
              title: item.name,
              price: item.price,
              info: item.introduction,
              teacher: item.teacherName,
              degree: vm.getDegree(item.degree),
              id: item.id,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      await vm
        .fetchAPI("get", `${process.env.VUE_APP_URL_API}/Teacher/New`)
        .then((res) => {
          res.data.forEach((item) => {
            vm.teacherData.push({
              imgUrl: vm.imageProcess(item.avatar),
              name: item.nickName,
              info: item.simpleIntroduction,
              id: item.memberId,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    redirectToClass(id) {
      this.$router.push(`/ChargeMeTribe/Class/${id}`);
    },
    redirectToTeacher(id) {
      this.$router.push(`/ChargeMeTribe/TeacherIntroduce/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.55);
  &:hover {
    color: rgba(0, 0, 0, 0.55);
  }
}
#carousel {
  width: 100vw;
}
.baseline {
  border-color: #ffd964 !important;
}
.el-carousel {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.consult {
  height: 20rem;
  background-color: rgb(214, 214, 214);
  padding: 0;
  box-shadow: 1px 1px 3px 1px rgb(206, 205, 205);
  transition: 0.2s;
  &:hover {
    box-shadow: 3px 3px 5px 3px rgb(206, 205, 205);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.card {
  img {
    width: 100%;
    height: 15vh;
    object-fit: cover;
  }
  .info {
    height: 2rem;
    overflow: hidden;
  }
  &:hover {
    box-shadow: 3px 3px 5px 3px rgb(206, 205, 205);
    cursor: pointer;
  }
}
.teacher {
  img {
    height: 20vh;
  }
}
:deep .el-card {
  height: 100%;
  .el-card__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    .card-body {
      display: flex;
      flex-direction: column;
      .content {
        flex-grow: 1;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .card {
    img {
      height: 22vh;
    }
  }
  .teacher {
    img {
      height: 30vh;
    }
  }
}
</style>
